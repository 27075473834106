import { FC } from 'react'
import Link from 'next/link'

import MainLayout from 'containers/main-layout/main-layout'

import { PROMO_REDIRECT_URL } from 'constants/data'

import styles from 'styles/404.module.scss'

const Page404: FC = () => {
  return (
    <MainLayout noFooter noBtnUp isAbsolute>
      <div className={styles.container}>
        <header className={styles.wrapper}>
          <h2 className={styles.title}>404</h2>
          <div className={styles.text}>
            <p>Ошибка. Страница не существует</p>
            <Link className={styles.link} href={PROMO_REDIRECT_URL}>
              Вернуться на главную
            </Link>
          </div>
        </header>
      </div>
    </MainLayout>
  )
}

export default Page404
